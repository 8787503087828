import * as React from "react";
import { Link } from "gatsby";

import FIRST_WORKS from "../../assets/img/works-1-1.jpg";
import SECOND_WORKS from "../../assets/img/works-2-1.jpg";
import THIRD_WORKS from "../../assets/img/works-3-1.jpg";
import FOURTH_WORKS from "../../assets/img/works-4-1.jpg";

export const WorksSection = () => {
  return (
    <section className="mb-12 mx-auto px-6 md:mb-24 md:px-10 lg:mb-32 lg:max-w-5xl">
      <div className="flex flex-col items-center justify-center mb-12 lg:mb-20">
        <div className="">
          <h2 className="text-primary-blue text-2xl tracking-wider md:text-4xl">
            WORKS
          </h2>
        </div>
        <div>
          <span className="text-xs md:text-sm">実例紹介</span>
        </div>
      </div>
      <div className="flex flex-wrap items-start justify-between mb-2 md:mb-8 lg:mb-12">
        <Link
          to="/work/yuurakuchou-lumine"
          className="group mb-6 pr-3 w-1/2 h-full lg:px-2 lg:w-1/4"
        >
          <div className="blue-overlay relative mb-2 w-full h-40 rounded overflow-hidden md:h-64 lg:h-48">
            <img
              className="w-full h-full object-cover transform group-hover:scale-110 duration-300"
              src={FIRST_WORKS}
              alt=""
            />
          </div>
          <div className="flex flex-col">
            <span className="text-xs font-semibold md:text-sm">
              有楽町ルミネ　Spick and Span
            </span>
          </div>
        </Link>
        <Link
          to="/work/lucua-osaka"
          className="group mb-6 pl-3 w-1/2 h-full lg:px-2 lg:w-1/4"
        >
          <div className=" blue-overlay relative mb-2 w-full h-40 rounded overflow-hidden md:h-64 lg:h-48">
            <img
              className="w-full h-full object-cover transform group-hover:scale-110 duration-300"
              src={SECOND_WORKS}
              alt=""
            />
          </div>
          <div className="flex flex-col">
            <span className="text-xs font-semibold md:text-sm">
              LUCUA osaka　Le Talon
            </span>
          </div>
        </Link>
        <Link
          to="/work/grandfront-osaka"
          className="group mb-6 pr-3 w-1/2 h-full lg:px-2 lg:w-1/4"
        >
          <div className="relative mb-2 w-full h-40 rounded overflow-hidden md:h-64 lg:h-48">
            <img
              className="w-full h-full object-cover transform group-hover:scale-110 duration-300"
              src={THIRD_WORKS}
              alt=""
            />
          </div>
          <div className="flex flex-col">
            <span className="text-xs font-semibold md:text-sm">
              グランフロント大阪　nest Robe
            </span>
          </div>
        </Link>
        <Link
          to="/work/laporte-aoyama"
          className="group mb-6 pl-3 w-1/2 h-full lg:px-2 lg:w-1/4"
        >
          <div className="blue-overlay relative mb-2 w-full h-40 rounded overflow-hidden md:h-64 lg:h-48">
            <img
              className="w-full h-full object-cover transform group-hover:scale-110 duration-300"
              src={FOURTH_WORKS}
              alt=""
            />
          </div>
          <div className="flex flex-col">
            <span className="text-xs font-semibold md:text-sm">
              ラ・ポルト青山　CITY SHOP NOODLE
            </span>
          </div>
        </Link>
      </div>
      <div className="relative mx-auto w-fit-content">
        <Link
          className="btn-background-slide group flex items-center justify-center mx-auto py-2 w-48 bg-primary-blue md:py-4 md:w-64"
          to="/work"
        >
          <span className="z-10 group-hover:text-primary-blue text-white text-sm tracking-wider transition duration-300 ease-in-out md:text-base">
            VIEW MORE
          </span>
        </Link>
      </div>
    </section>
  );
};
