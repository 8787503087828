import * as React from 'react';
import { Seo } from '../components/seo';
import { Layout } from '../layout';
import { WorksSection } from '../section/index/works';
import { ServicesSection } from '../section/index/services';
import { OurQualitySection } from '../section/index/our-quality';
import HOME_IMG from '../assets/img/bg-home.jpeg';

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="株式会社FINE｜大阪府八尾市、東京都世田谷区を拠点に商業施設の設計・施工・監理しています。" />
      <section>
        <div
          style={{ backgroundImage: `url(${HOME_IMG})` }}
          className="
          relative
          flex
          items-center
          justify-center
          px-6
          w-screen
          h-70vh
          bg-cover bg-bottom
          overflow-hidden
          md:px-10
          md:h-95vh
          lg:px-16
        "
        >
          <h1 className="flex flex-col w-full tracking-wider md:tracking-widest">
            <span className="text-white font-serif text-xl md:text-3xl lg:text-right">
              明日、エピソードとなる空間を。
            </span>
          </h1>
          <div>
            <svg
              className="
              wave
              absolute
              bottom-0
              inset-x-0
              -mb-1
              w-200vw
              text-white
              fill-current
            "
              viewBox="0 0 1440 64"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M360 19.483C240 -6.49433 120 -6.49433 0 19.483V63.5H1440V19.483C1320 45.4603 1200 45.4603 1080 19.483C960 -6.49433 840 -6.49433 720 19.483C600 45.4603 480 45.4603 360 19.483Z" />
            </svg>
          </div>
        </div>
      </section>
      <main>
        <OurQualitySection />
        <ServicesSection />
        <WorksSection />
      </main>
    </Layout>
  );
};

export default IndexPage;
