import * as React from "react";
import { Link } from "gatsby";

import MENU_SHOP_INTERIOR from "../../assets/img/menu-shop-interior.jpeg";
import MENU_RESTAURANT_INTERIOR from "../../assets/img/menu-restaurant-interior.jpeg";
import MENU_ORDER_FURNITURE from "../../assets/img/menu-ordered-furniture.jpeg";

export const ServicesSection = () => {
  return (
    <section className="mb-12 md:mb-24 lg:mb-32">
      <div className="w-screen h-full bg-primary-lightblue transform -skew-y-6">
        <div
          className="
                  flex flex-wrap
                  mx-auto
                  pb-10
                  pt-4
                  px-6
                  transform
                  skew-y-6
                  md:pb-16
                  md:pt-8
                  md:px-10
                  lg:max-w-5xl
                "
        >
          <div
            className="
                    my-6
                    w-full
                    h-fit-content
                    md:-mt-6
                    md:mb-12
                    lg:inline-block
                    lg:mt-8
                    lg:w-1/5
                    lg:transform
                    lg:rotate-90
                  "
          >
            <h2
              className="
                      text-primary-blue text-2xl
                      tracking-wider
                      md:text-4xl
                      lg:text-5xl
                    "
            >
              SERVICES
            </h2>
          </div>
          <div className="flex flex-wrap w-full lg:w-4/5">
            <div className="mb-8 w-1/2">
              <div className="mr-2 md:mr-6">
                <Link to="/shop-interior" className="group">
                  <div className="rounded overflow-hidden">
                    <img
                      className="
                              w-full
                              h-40
                              object-cover
                              transform
                              group-hover:scale-110
                              duration-300
                              md:h-64
                            "
                      src={MENU_SHOP_INTERIOR}
                      alt=""
                    />
                  </div>
                  <h2 className="my-2 text-sm tracking-wider md:text-base">
                    物販店舗
                  </h2>
                  <p className="text-xs tracking-wider md:text-sm">
                    もう一度訪れたくなるような空間を創ります。
                  </p>
                </Link>
              </div>
            </div>
            <div className="mb-8 w-1/2 transform translate-y-32 md:translate-y-40">
              <div className="ml-2 md:ml-6">
                <Link to="restaurant-interior" className="group">
                  <div className="rounded overflow-hidden">
                    <img
                      className="
                              w-full
                              h-40
                              object-cover
                              transform
                              group-hover:scale-110
                              duration-300
                              md:h-64
                            "
                      src={MENU_RESTAURANT_INTERIOR}
                      alt=""
                    />
                  </div>
                  <h2 className="my-2 text-sm tracking-wider md:text-base">
                    飲食店舗
                  </h2>
                  <p className="text-xs tracking-wider md:text-sm">
                    失敗しないお店創りのお手伝いをさせて頂きます
                  </p>
                </Link>
              </div>
            </div>
            <div className="mb-8 w-1/2">
              <div className="mr-2 md:mr-6">
                <Link to="ordered-furniture" className="group">
                  <div className="rounded overflow-hidden">
                    <img
                      className="
                              w-full
                              h-40
                              rounded
                              object-cover
                              overflow-hidden
                              transform
                              group-hover:scale-110
                              duration-300
                              md:h-64
                            "
                      src={MENU_ORDER_FURNITURE}
                      alt=""
                    />
                  </div>
                  <h2 className="my-2 text-sm tracking-wider md:text-base">
                    別注家具
                  </h2>
                  <p className="text-xs tracking-wider md:text-sm">
                    精度を高め、確かな製品を生み出す。
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
